<template>
	<div v-if="categories && categories.length && hasKnowledges">
		<div class="container">
			<div
			v-if="!title"
			class="canban__name"
			ref="margin"
			>
			<div class="canban__name-inner" @click="redirect(data.id)">
				{{ title ? title : data.name }}
				<div class="canban__name-icon">
					<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10 1L5.5 5.5L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
			</div>
		</div>
		<div
		v-else
		class="canban__name--for-all"
		ref="margin"
		@click="redirect('for-all')"
		>
		{{ title }}
	</div>
</div>
<div class="canban" ref="slider">
	<div
	class="canban-wr"
	:style="{'margin-left': x + 'px', 'margin-right': x - 20 + 'px', 'left': 0}"
	ref="sliderWr"
	@mousedown="sliderMove"
	@mouseup="mouseUp"
	>
	<template v-for="(item, index) in categories">
		<DndCard
		v-if="(item.knowledges && item.knowledges.length) || ((item.subcategories && item.subcategories.length) && item.subcategories.some(el => el.knowledges.length))"
		:key="index"
		:item="item"
		:index="index"
		:user-can-edit="userCanEdit"
		@deleteCategory="deleteCategory"
		@editPinState="editPinState"
		@updateCategoryName="updateCategoryName"
		@deleteItem="deleteItem"
		@onDrop="onDrop"
		@onSubcategoryDrop="onSubcategoryDrop"
		@deleteSubcategoryItem="deleteSubcategoryItem"
		@deleteSubcategory="deleteSubcategory"
		/>
	</template>
</div>
</div>
</div>
</template>

<script>
// import usable functional
import Notify      from '@/services/helpers/notify';
import ApiClient   from '@/api/api.client';
import UsersModule from '@/store/modules/users/UsersModule';

// import components
import DndCard from '@/components/documents/dashboard-item/dndCard.vue';

export default {
	name: 'DocumentsDashboardCanban',
	
	components: { DndCard },
	
	props:
	{
		data:
		{
			type: Object,
			default: () => ({}),
		},
		
		title:
		{
			type: String,
			default: '',
		},
	},
	
	data() {
		return {
			categories: this.data && this.data.items && this.data.items.categories ? this.data.items.categories : null,
			x: null,
			isPressed: false,
			curPos: 0,
			diff: null,
		};
	},
	
	computed:
	{
		userCanEdit() { return UsersModule.currentUser.admin || UsersModule.currentUser.permitions.knowledge.manage; },
		hasKnowledges()
		{
			let has = false;
			if (this.categories && this.categories.length)
			{
				has = this.categories.some(category => {
					return (category.knowledges && category.knowledges.length) || (category.subcategories && category.subcategories.some(el => el.knowledges.length));
				});
			}
			return has;
		},
	},
	
	methods:
	{
		redirect(item)
		{
			if (item === 'for-all')
			this.$router.push('/documents/dashboard/0')
			else
			this.$router.push(`/documents/dashboard/${item}`)
		},
		
		mouseUp()
		{
			this.isPressed = false
			this.curPos = this.$refs.sliderWr.style.left.match(/-?\d+/g)[0]
			this.$refs.sliderWr.style.transition = '.35s ease-in-out'
			
			if (this.curPos > 0)
			{
				this.$refs.sliderWr.style.left = '0'
				this.curPos = this.$refs.sliderWr.style.left.match(/-?\d+/g)[0]
			}

			if (this.curPos < -this.diff)
			{
				this.$refs.sliderWr.style.left = `-${this.diff}px`
				this.curPos = this.$refs.sliderWr.style.left.match(/-?\d+/g)[0]
			}
		},
		
		sliderMove(event)
		{
			if (event.target === this.$refs.sliderWr)
			{
				this.isPressed = true
				const touchPosition = event.clientX
				this.$refs.sliderWr.style.transition = 'unset'
				
			
				event.target.onmousemove = (event) =>
				{
					if (this.isPressed && this.diff > 0)
						this.$refs.sliderWr.style.left = `${Number(this.curPos) + (event.clientX - touchPosition)}px`
				}
			}	
		},
		
		calculateMargin()
		{
			if (this.$refs.margin)
			{
				const rect = this.$refs.margin.getBoundingClientRect();
				this.x = rect.x;
			}
		},
		
		
		// dnd
		deleteCategory(value)
		{
			const idx = this.categories.findIndex(el => el.id === value);
			
			ApiClient.categories.deleteCategory(value)
			.then(() => {
				this.categories.splice(idx, 1);
				Notify.success('Категория удалена');
			})
			.catch(() => Notify.error('Что-то пошло не так, попробуйте позже.'));
		},
		
		editPinState({ id, state })
		{
			const idx = this.categories.findIndex(el => el.id === id);
			
			ApiClient.categories.updateCategory(id, { pinned: state })
			.then(() => {
				this.categories[idx].pinned = state;
				Notify.success(`Категория ${state ? 'закреплена' : 'откреплена'}`);
			});
		},
		
		updateCategoryName({ id, value })
		{
			const idx = this.categories.findIndex(el => el.id === id);
			
			ApiClient.categories.updateCategory(id, { name: value })
			.then(() => {
				this.categories[idx].name = value;
				Notify.success('Категория изменена');
			})
			.catch(() => Notify.success('Название категории должно быть уникальным'));
		},
		
		deleteItem({ categoryId, itemId })
		{
			const categoryIdx = this.categories.findIndex(el => el.id === categoryId);
			const knowledgeIdx = this.categories[categoryIdx].knowledges.findIndex(el => el.id === itemId);
			
			ApiClient.knowledge.deleteKnowledge(itemId).then(() => {
				Notify.success('Знание удалено');
				this.categories[categoryIdx].knowledges.splice(knowledgeIdx, 1);
			});
		},
		
		onDrop({ groupIndex, value })
		{
			const { removedIndex, addedIndex, payload } = value;
			
			if (removedIndex === null && addedIndex === null)
			return;
			
			if (removedIndex !== null)
			this.categories[groupIndex].knowledges.splice(removedIndex, 1);
			
			if (addedIndex !== null)
			{
				this.categories[groupIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: '0',
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},
		
		onSubcategoryDrop({ groupIndex, subcategoryIndex, dropResult })
		{
			const { removedIndex, addedIndex, payload } = dropResult;
			
			if (removedIndex === null && addedIndex === null)
			return;
			
			if (removedIndex !== null)
			this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(removedIndex, 1);
			
			if (addedIndex !== null)
			{
				this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const subcategoryId = this.categories[groupIndex].subcategories[subcategoryIndex].id;
				
				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: subcategoryId,
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},
		
		deleteSubcategoryItem({ groupIndex, subcategoryIndex, event })
		{
			const subcategoryIdx = this.categories[groupIndex].subcategories.findIndex(el => el.id === subcategoryIndex);
			const subcategoryItemIdx = this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.findIndex(el => el.id === event);
			
			ApiClient.knowledge.deleteKnowledge(event).then(() => {
				Notify.success('Знание удалено');
				this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.splice(subcategoryItemIdx, 1);
			});
		},
		
		deleteSubcategory({ groupIndex, subcategoryIndex })
		{
			const id = this.categories[groupIndex].subcategories[subcategoryIndex].id;
			ApiClient.subCategories.deleteSubcategory(id).then(() => {
				Notify.success('Подкатегория удалена');
				this.categories[groupIndex].subcategories.splice(subcategoryIndex, 1);
			});
		},
	},
	
	mounted() {
		this.diff = this.$refs.sliderWr?.offsetWidth - this.$refs.slider?.offsetWidth + 22;
		this.calculateMargin();
	},
}
</script>

<style lang="scss" scoped>
.container
{
	padding: 0 22px;
	max-width: 1440px;
	margin: 0 auto;
}

.canban__name
{
	margin-bottom: 40px;
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 1px solid #FFFFFF80;
}

.canban__name--for-all
{
	cursor: pointer;
	margin-bottom: 40px;
	width: 100%;
	font-size: 18px;
	font-weight: 300;
	color: #ffffff;
	padding: 0 0 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 22px;
	border-bottom: 1px solid #FFFFFF80;
}

.canban__name-inner
{
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	width: max-content;
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	color: #FFFFFFCC;
	
	&:hover
	{
		color: #FFFFFF;
		.canban__name-icon { width: 52px; }
	}
}

.canban__name-icon
{
	cursor: pointer;
	position: relative;
	width: 13px;
	height: 1px;
	background: white;
	transition: all .5s ease;
	
	svg
	{
		position: absolute;
		top: -3px;
		right: -4px;
		transform: rotate(270deg);
	}
}

.canban
{
	overflow-x: scroll;
	&::-webkit-scrollbar { width: 0; height: 0; }
}

.canban-wr
{
	width: max-content;
	padding-top: 10px;
	padding-bottom: 50px;
	display: flex;
	gap: 10px;
	position: relative;
	
	.documents-dashboard__item
	{
		width: 320px;
		height: max-content;
	}

	div
	{
		user-select: none;
	}
}
</style>