
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { CategoriesResponse } from '@/models/dto/responses/categories.response';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Button from '@/components/ui/button';
import DocumentMeta from './DocumentMeta.vue';
import ButtonGroup from '@/components/ui/button-group';
import Label from '@/components/ui/label';
import Icon from '@/components/ui/icon/Icon.vue';
import FindOrCreate from '@/components/context-menu/repeatable/FindOrCreate.vue';
import CategoriesModule from '@/store/modules/categories/CategoriesModule';
import { SubCategories } from '@/models/dto/responses/subcategories.response';
import SubCategoriesModule from '@/store/modules/subcategories/SubCategoriesModule';
import RolesModule from '@/store/modules/roles/RolesModule';
import Editor from '@/modules/settings/views/directions/components/Editor.vue';
import Notify from '@/services/helpers/notify';

@Component({
    name: 'DocumentMetaCategory',
    components: { DocumentMeta, Button, ButtonGroup, Label, Icon, ContextMenuSpawner, FindOrCreate, Editor },
})
export default class DocumentMetaCategory extends Vue {
    public editorHeader = '';
    public editorFunction: Function = null;
    public selectedCategory = 0;
    public selectedSubcategory = 0;
    public isLoaded = false;
    public categoryValue = '';
    public subCategoryValue = '';
    public newSubCategory = false;
    public defaultSubCategory = {
        id: 0,
        name: 'Без подкатегории',
        // eslint-disable-next-line
        parent_id: 0,
    };
    public newCategory = false;
    public showEditor = false;
    public lastCreatedCategory = [] as null;

    @Prop() subCategoryId: number;
    @Prop({ default: false }) mode: string;
    @Prop({ default: false }) categoryId: number;
    @Prop({ default: false }) disabled: boolean;

    mounted() {
        this.selectedSubcategory = this.subCategoryId;
    }
    async created() {
        await this.initPage();
    }
    get contextStatus() {
        return RolesModule.closed;
    }
    async createSubCategory() {
        const subCat = { parentId: this.selectedCategoryItem.id, name: this.subCategoryValue };
        await SubCategoriesModule.fetchSubCreateCategory(subCat)
            .then((data) => {
                this.onSubCategorySelect(data.data.id)
                this.subcategories = this.subcategories.concat(SubCategoriesModule.data as SubCategories);
                Notify.success('Подкатегория создана');
            })
    }
    openEditor(heading: string, func: Function) {
        this.editorHeader = heading;
        this.editorFunction = func;
        this.showEditor = true;
    }
    closeEditor() {
        this.showEditor = false;
    }
    createNewCategory(newName: { name: string }) {
        this.categoryValue = newName.name;
        this.createCategory();
        this.closeEditor();
    }
    createNewSubcategory(newName: { name: string }) {
        this.subCategoryValue = newName.name;
        this.createSubCategory();
        this.closeEditor();
    }
    updateContextStatus() {
        RolesModule.setClosedContext(false);
    }
    @Watch('contextStatus')
    contextChanged(val: boolean) {
        if (val) {
            this.resetSearch();
        }
    }
    get selectedCategoryItem() {
        return this.categories.find((category: any) => category.id === this.selectedCategory);
    }

    get selectedSubcategoryItem() {
        return this.subcategories.find(category => category.id === this.selectedSubcategory)
            ? this.subcategories.find(category => category.id === this.selectedSubcategory)
            : this.defaultSubCategory;
    }

    public subcategories: Array<SubCategories> = [
        {
            id: 9999,
            name: 'Без подкатегории',
            // eslint-disable-next-line
            parent_id: -999,
        },
    ];
    async initPage() {
        await this.loadCategories();
        await this.loadCategory();
        this.onLoaded();
    }

    @Emit('pageLoaded')
    onLoaded() {
        this.isLoaded = true;
        return true;
    }
    resetSearch() {
        this.newCategory = false;
        this.categoryValue = '';
    }

    get categories()
    {
        return CategoriesModule.data
    }
    async loadCategories() {
        await CategoriesModule.fetchCategories()
    }
    async loadSubCategories(parentId: number) {
        await this.$nextTick();
        await SubCategoriesModule.fetchSubCategories(parentId).then(() => {
            this.subcategories = [this.defaultSubCategory, ...(SubCategoriesModule.data as SubCategories[])];
        });
    }
    async createCategory() {
        if (this.categoryValue) {
            await CategoriesModule.fetchCreateCategory(this.categoryValue, false)
                .then((data) => {        
                    this.onCategorySelect(data.data.id)
                    Notify.success('Категория создана');
                })
            await this.$nextTick();
            this.resetSearch();
        }
    }
    loadCategory() {
        if (this.categoryId) {
            CategoriesModule.fetchCategory(this.categoryId.toString()).finally(() => {
                const resp = CategoriesModule.category as CategoriesResponse;
                this.selectedCategory = resp.id;
            });
            this.loadSubCategories(this.selectedCategory);
        }
    }
    onCategorySelect(id: number) {
        this.selectedCategory = id;
    }

    onSubCategorySelect(id: number) {
        this.selectedSubcategory = id;
    }

    @Watch('categoryId')
    onCategoryLoaded() {
        this.loadCategory();
    }

    @Watch('subCategoryId')
    setSelectedSubCategory() {
        this.selectedSubcategory = this.subCategoryId;
    }

    @Watch('selectedCategory')
    onChangeCategory() {
        this.loadSubCategories(this.selectedCategory);
    }
    @Watch('selectedCategory')
    @Watch('selectedSubcategory')
    @Emit('change')
    onChange() {
        return [this.selectedCategory, this.selectedSubcategoryItem.id];
    }
}
