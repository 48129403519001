<template>
    <div class="ediotr-wr">
        <hr class="pulse-document__divider" v-if="$isDesktop"/>
        <div class="editor__toolbar-wrapper" v-if="editor && mode != 'view'" :class="{ 'editor__toolbar-wrapper--shadowed': toolbarOnTop }">
            <div class="editor__toolbar">
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить эмодзи</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{ 'link-acitve': showEmojiContext }"
                        @click="emojiToggle"
                    >
                        <Icon :data-name="'emoji'" name="emoji" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить упоминание</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        @click="handleClickMentions"
                    >
                        <Icon name="mention" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить ссылку</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        @click="toggleLink"
                        :class="{'link-acitve': editor.isActive('link') || showLinkModal}"
                    >
                        <Icon name="link" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить список</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{'link-acitve': editor.isActive('blockquote')}"
                        @click="toggleBlockQuote"
                    >
                        <Icon name="list" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить нумерованный список</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{'link-acitve': editor.isActive('orderedList')}"
                        @click="toggleOrderList"
                    >
                        <Icon name="ordered" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить маркированный список</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{'link-acitve': editor.isActive('bulletList')}"
                        @click="toggleBulletList"
                    >
                        <Icon name="listdash" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить изображение</p>
                            </div>
                        </div>

                    <button slot="reference" class="tooltip-wrapper" @click="
                        editor
                            .chain()
                            .focus()
                            .setSlider()
                            .run()
                    ">
                        <Icon name="image" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить видео</p>
                            </div>
                        </div>

                    <button slot="reference" class="tooltip-wrapper" id="add" @click="showVideo">
                        <Icon name="youtube" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Вставить разделитель</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{'link-disabled': editor.isActive('blockquote') || editor.isActive('orderedList') ||  editor.isActive('bulletList') }"
                        :disabled="editor.isActive('blockquote') || editor.isActive('orderedList') ||  editor.isActive('bulletList')"
                        @click="editor
                            .chain()
                            .focus()
                            .setHorizontalRule()
                            .run()
                        "
                    >
                        <Icon name="divider" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Заголовок</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{ 'link-acitve': editor.isActive('heading', { level: 1 }) }"
                        @click="
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 1 })
                                .run()
                        "
                    >
                        <Icon name="header" size="18"></Icon>
                    </button>
                </popper>
                <popper
                    trigger="hover"
                    :visible-arrow="false"
                    :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0.10x' } }
                        }">
                        <div class="popper">
                            <div class="tooltip-content">
                                <p>Заголовок</p>
                            </div>
                        </div>

                    <button
                        slot="reference"
                        class="tooltip-wrapper"
                        :class="{ 'link-acitve': editor.isActive('heading', { level: 2 }) }"
                        @click="
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run()
                        "
                    >
                        <Icon name="header2" size="18"></Icon>
                    </button>
                </popper>
            </div>
        </div>
        <VEmojiPicker
            v-if="showEmojiContext"
            :dark="true"
            ref="emoji"
            v-click-outside="changeEmojiVisible"
            @select="showEmoji"
            :emojisByRow="7"
            :i18n="i18n"
            :emojiSize="24"
            :style="{top: y + 20 + 'px', left: x + 'px'}"
            class="emojiCustomPicker"
        />
        <LinkConfirm @select="setLink($event)" @close="showLinkModal = false;" v-if="showLinkModal" :previousUrl="previousUrl" v-click-outside="closeLinkModal" />
        <div class="editor-content__wrapper" @keydown="handleKeyDown">
            <editor-content
                v-model="content"
                :extensions="extensions"
                ref="editor"
                :editor="editor"
                @keydown="handleKeyDown"
            />
        </div>
        <PopupDialogDefault
            v-if="videoContextMenu"
            inputType="text"
            @save="addVideo($event)"
            @closed="videoContextMenu = false"
            message="Вставьте ссылку на ролик"
            placeholderText="http..."
        />
    </div>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import { VEmojiPicker } from 'v-emoji-picker';
import Emoji from '@/plugins/extension-emoji'
import Notify from '@/services/helpers/notify';
import History from '@tiptap/extension-history'
import Mention from '@tiptap/extension-mention'
import customParagraph from '@/plugins/customParagraph';
import Icon from '@/components/ui/icon/Icon.vue';
import LinkConfirm from '@/components/ui/popups/LinkConfirm.vue'
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import sugg from './sugg'
import suggestion from './suggestion.js';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import VueComponent from '@/plugins/Extension';

import Link from '@tiptap/extension-link';
import UnlinkOnSpace from '@/plugins/UnlinkOnSpace'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Youtube from '@/plugins/Video';
import UniqueID from '@tiptap/extension-unique-id';
// import TaskItem from '@tiptap/extension-task-item';
// import TaskList from '@tiptap/extension-task-list';
import Blockquote from '@tiptap/extension-blockquote';
import Image from '@tiptap/extension-image/src/index';
import { Editor, EditorContent, Node } from '@tiptap/vue-2';
import BulletList from '@tiptap/extension-bullet-list';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading'
import ListItem from '@tiptap/extension-list-item'
import HardBreak from '@tiptap/extension-hard-break'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import OrderedList from '@tiptap/extension-ordered-list'

export default {
    props: ['content', 'mode'],
    components: {
        Popper,
        EditorContent,
        Icon,
        VEmojiPicker,
        LinkConfirm,
        PopupDialogDefault,
    },

    data() {
        return {
            i18n:
            {
                search: 'Поиск',
                categories:
                {
                    Activity: 'Atividades',
                    Flags: 'Bandeiras',
                    Foods: 'Comida',
                    Frequently: 'Frequentes',
                    Objects: 'Objetos',
                    Nature: 'Natureza',
                    Peoples: 'Pessoas',
                    Symbols: 'Símbolos',
                    Places: 'Locais',
                },
            },
            x: null,
            y: 130,
            loaded: false,
            editor: null,
            rowNum: null,
            toolbarOnTop: false,
            videoContextMenu: false,
            customImage: null,
            showLinkModal: false,
            showEmojiContext: false,
            swiper: null,
            swiperName: '',
            filePath: null,
            extensions:
                [
                    History,
                    Document,
                    Paragraph,
                    EditorContent,
                    Text,
                    Youtube.configure({
                        controls: true,
                    }),
                    Link.configure({
                        openOnClick: false,
                        linkOnPaste: false,
                    }),
                    Emoji.configure({
                        forceFallbackImages: true,
                        enableEmoticons: true,
                        sugg,
                    }),
                    HorizontalRule.configure({
                        HTMLAttributes: {
                            class: 'my-custom-class',
                        },
                    }),
                    Blockquote,
                    Image,
                    Mention.configure({
                        renderLabel({ options, node }) {
                            return `${node.attrs.label}`
                        },
                        HTMLAttributes: {
                            class: 'mention',
                            onclick: 'window.open(`/team/user/${event.target.getAttribute("data-id")}`, `_blank`)',
                        },
                        suggestion,
                    }),
                    customParagraph,
                    OrderedList.configure({
                        HTMLAttributes: {
                            itemTypeName: 'listItem',
                            class: 'ordered-list',
                        },
                    }),
                    Heading,
                    ListItem,
                    BulletList.configure({
                    HTMLAttributes: {
                            class: 'horisontal-select',
                        },
                    }),
                    VueComponent,
                    UniqueID.configure({
                        types: ['vue-component', 'paragraph', VueComponent, 'VueComponent', 'Vue-Component', 'vueComponent'],
                    }),
                    Placeholder.configure({
                        placeholder: this.$tc('Documents.Document.AddText'),
                    }),
                    HardBreak.configure({
                        onlyIn: ['paragraph'],
                    }),
                ],
            result: null,
            videoUrl: null,
        };
    },
    computed:
    {
        previousUrl()
        {
            return this.editor.getAttributes('link').href
        },
        currentWidth()
        {
            return this.$isMobile
        },
    },
    async mounted() {
        this.editor = new Editor({

        editable: this.mode === 'view' ? false : true,
        extensions: this.extensions,

        content: '',
        onUpdate: (e) =>
        {
            if(e.transaction.meta && e.transaction.meta?.preventAutolink) return;

            if(this.loaded)
            {
                this.$emit('updated', this.editor.getJSON().content)
            }
            this.loaded = true;
        },
        onCreate: () => 
            {
                if(this.mode !== 'create') {
                    this.editor.commands.setContent(this.content);
                }
                const el = document.querySelector('.ProseMirror')

                const events = ['mouseup', 'keyup']
                events.forEach((event) => 
                {
                    el.addEventListener(event, this.setCursorPos)
                })
            },
        });
        if(this.mode !== 'view') {
            await TeamsModule.fetchTeams();
            window.onscroll = () => this.updateToolbarPos();
        };

    },
    methods: {
        handleMentionClick(value) {
            console.log(value);
        },
        handleKeyDown(event)
        {
            const { selection } = this.editor.state;
            const $from = selection.$from;

            if (event.key === 'Enter' && event.shiftKey && $from.node($from.depth).type.name === 'heading')
            {
                event.preventDefault();
                const listItemPos = $from.depth - 2;
                if (listItemPos === 1)
                {
                    this.editor.chain().focus().enter().run();
                    this.editor.chain().focus().clearNodes().run();
                }
            }
            else if (event.key === 'Enter')
            {
                event.preventDefault();

                if ($from.node($from.depth).type.name === 'heading' && $from.depth === 1)
                {
                    // collecting text
                    const text = $from.parent.textContent;

                    // generating class for a paragraph
                    const paragraphClass = this.generateRandomClass(20);

                    // create new paragraph
                    this.editor.commands.insertContent({
                        type: 'customParagraph',
                        attrs: {
                            class: paragraphClass,
                        },
                        content: [{ type: 'text', text }],
                    });

                    // finding extra elements ( it creates two extra h1, idk why, never worked with it )
                    const focusedElement   = document.activeElement;
                    const createdParagraph = focusedElement.querySelector(`.${paragraphClass}`);
                    createdParagraph.previousElementSibling.remove();
                    createdParagraph.nextElementSibling.remove();
                }
            }
        },
        generateRandomClass(length) {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
          let result       = '';

          for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result            += characters.charAt(randomIndex);
          }

          return result;
        },
        toggleOrderList()
        {
            this.editor.commands.unsetBlockquote();
            this.editor.chain().focus().toggleOrderedList().run();
        },
        toggleLink()
        {
            this.showLinkModalSet()
        },
        showVideo()
        {
            this.videoContextMenu = !this.videoContextMenu
        },
        toggleBulletList()
        {
            this.editor.commands.unsetBlockquote();
            this.editor.chain().focus().toggleBulletList().run();
        },
        toggleBlockQuote()
        {
            this.editor.chain().focus().liftListItem('listItem').run()
            if(this.editor.isActive('bulletList'))
            {
                this.editor.chain().focus().unsetBulletList().run()
            }
            if(this.editor.isActive('orderedList'))
            {
                this.editor.chain().focus().unsetOrderedList().run()
            }
            this.editor.chain().focus().toggleBlockquote().run()
        },
        updateToolbarPos() {
            const toolbarOffset = document.querySelector('.editor__toolbar-wrapper').offsetTop;
            if (toolbarOffset) {
                this.toolbarOnTop = true;
            } else {
                this.toolbarOnTop = false;
            }
        },
        handleClickMentions()
        {
            this.editor.commands.insertContent('@')
        },
        emojiToggle()
        {
            this.showEmojiContext = !this.showEmojiContext
            if(this.showEmojiContext)
            {
                this.$nextTick(() =>
                {
                    this.$refs.emoji.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                })
            }
        },
        addVideo(url) {
            const re = new RegExp('^(http|https)://', 'i');
            const match = re.test(url);
            if(match)
            {
                this.videoUrl = url;
                this.editor.commands.setYoutubeVideo({
                    src: url,
                    width: 780,
                    height: Math.max(180, parseInt(this.height, 10)) || 480,
                })
                this.editor.commands.enter();
                this.videoContextMenu = false;
            }
            else
            {
                Notify.error('Некорректная ссылка')
            }
        },
        closeLinkModal()
        {
            this.showLinkModal = false
        },
        showLinkModalSet() 
        {
            if(this.previousUrl)
            {
                this.editor.chain().focus().unsetLink().run()
            }
            else
            {
                this.showLinkModal = !this.showLinkModal;
            }
        },
        setLink(url) 
        {
            // cancelled
            if (url === null) {
                this.editor.chain().focus().unsetLink().run()
            }
            // empty
            if (url === '') {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()
                return
            }

            const {from, to} = this.editor.state.selection;

            if(from === to) 
            {
                let fullLink = url;
                if (!url.startsWith('http')) {
                    fullLink = 'https://' + url;
                }
                this.editor.commands.insertContent(`<a target="_blank" rel="noopener noreferrer nofollow" href="${fullLink}">${url}</a>`);
            }
            else
            {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: 'https://' +  url })
                    .run()
            }
                    // cancelled
                    if (url === null) {
                this.editor.chain().focus().unsetLink().run()
            }
            this.showLinkModal = false
        },
        setCursorPos()
        {
            // const selection = window.getSelection()
            const editableElementX = document.querySelectorAll('.ProseMirror')[0].getBoundingClientRect().x
            const selection = window.getSelection()
            const range = selection.getRangeAt(0)
            const rect = range.getBoundingClientRect()

            const cursorX = rect.left
            const cursorY = rect.top
            let node = selection.anchorNode

            if(selection.anchorNode.nodeName === '#text')
            {
                node = selection.anchorNode.parentElement
            }
            if(cursorX === 0)
            {
                this.x = 0
            }
            else
            {
                this.x = cursorX - editableElementX;
            }

            this.y = (node.getBoundingClientRect().top - document.querySelector('.ediotr-wr').getBoundingClientRect().top) + node.offsetHeight;

        },
        validateLinkResult(url)
        {
            const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            const regex = new RegExp(expression);
            const t = url;

            if (t.match(regex))
            {
                return url
            }
            else
            {
                return `http://${url}`
            }
        },
        changeEmojiVisible()
        {
            this.showEmojiContext = false
        },
        saveMy() {
            // Удаляет пустые строки в конце документа
            const content = this.editor.getJSON().content
            let lastContentExidedI = 0;
            for(let i = 0; i < content.length; i++)
            {
                if(content[i].content || content[i].type === 'horizontalRule' || content[i].attrs.content)
                {
                    lastContentExidedI = i;
                }
            }

            this.editor.commands.setContent(content.slice(0,lastContentExidedI+1));
            return this.editor.getJSON();
        },
        showEmoji(emoji) {
            this.editor.chain().focus().setEmoji(emoji).run()
        },
    },
    beforeDestroy() {
        const el = document.querySelector('.ProseMirror')

        const events = ['mouseup', 'keyup']

        events.forEach((event) => 
        {
            if(el)
            {
                el.removeEventListener(event, this.setCursorPos)
            }
        })

        window.onscroll = null;
        if(this.editor) {
            this.editor.destroy();
        };
    },
    watch:
    {
        currentWidth(val)
        {
            if(val)
            {
                this.editor.commands.setYoutubeVideo({
                    src: this.videoUrl,
                    width: 400,
                    height: 300,
                })
            }
        },
    },
};
</script>

<style lang="scss">
.popper
{
    background: #191919;
    border-radius: 19px;
    padding: 9px 13px;
    border: none;
    box-shadow: none;
    // transform: translateY(-30px) !important;
    .tooltip-content
    {
        p
        {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
        }

        color: rgba(255, 255, 255, 0.5);
    }
    .popper__arrow
    {
        display: none;
    }
}
p.new-paragraph {
  padding-bottom: 0;
}
.ediotr-wr
{
    width: 100%;
    position: relative;

    .editor__toolbar-wrapper {
        position: sticky;
        z-index: 100;
        top: 3px;
        width: fit-content;
        height: 49px;

        margin-left: -22px;
        padding: 0 15px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 19px;

        transition: box-shadow 0.25s ease-in-out, background-color 0s;

        @include on-tablet {
            top: 59px;

            .editor__toolbar {
                flex-grow: 1; 
            }
        }

        @include on-mobile-portrait {
            top: 56px;
            height: 56px;
            width: 100vw;
            margin: 0 -24px;
            border-radius: 0;
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);

            .editor__toolbar {
                flex-grow: 1; 
            }
        }

        &--shadowed {
            box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.5);
            background-color: #222222;
        }
    }
}
.editor-content__wrapper
{
    position: relative;
}
// p[data-id] {
//   padding-bottom: 0px !important; /* Установите нужное значение padding-bottom */
// }
.ProseMirror 
{
    padding-top: 8px;
    .horisontal-select
    {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
        .my-custom-class
        {
            position: absolute;
            top:-34px;
            width: 100%;
        }
        word-break:break-all;
        padding: 0;
        & > li::before
        {
            content: "";
            position: absolute;
            top: 4px;
            display: block;
            height: 1px;
            background: #8C8C8C;
            max-width: 22px;
            width: 100%;
            margin-right: 15px;
            margin-top: 11px;
            flex-basis: 100%;
        }
        list-style-type: none;
        li
        {
            position: relative;
            &:last-child
            {
                margin-bottom: 0;
            }
            p
            {
                padding: 0;
                margin: 0;
                max-width: 742px;
                width: 100%;
                margin-left: 35px;
            }
        }
    }
    & > p
    {
        // margin: 16px 0;
        margin: 0;
    }
    p
    {
        color: #DDD;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        word-break: break-word;
    }
    p
    {
        margin-top: 0;
    }
    p + p
    {
        margin-top: 24px;
    }
    h1
    {
        margin: 0;
    }
    h1 + p
    {
        margin-top: 21px;
    }
    p + h1
    {
        margin-top: 66px;
    }
    h2
    {
        margin: 0;
    }
    h2 + h1
    {
        margin-top: 55px;
    }
    h2 + p
    {
        margin-top: 19px;
    }
    p + h2
    {
        margin-top: 55px;
    }
    p + blockquote
    {
        margin-top: 24px;
    }
    blockquote
    {
        margin: 0;
    }
    blockquote + p
    {
        margin-top: 26px;
    }
    ul
    {
        margin: 0;
    }
    ul li
    {
      margin-bottom: 9px;
    }
    ol
    {
        margin: 0;
    }
    ol li
    {
      margin-bottom: 6px;
    }
    ul + p
    {
      margin-top: 25px;
    }
    p + ul
    {
      margin-top: 22px;
    }
    ol + p
    {
      margin-top: 25px;
    }
    p + ol
    {
      margin-top: 23px;
    }
    h2 + h1
    {
        margin-top: 54px;
    }
    h1 + h2
    {
        margin-top: 45px;
    }
    h1 + h1
    {
        margin-top: 61px;
    }
    ul + h1
    {
        margin-top: 66px;
    }
    ol + h1
    {
        margin-top: 67px;
    }
    h1 + ul
    {
        margin-top: 20px;
    }
    h1 + ol
    {
        margin-top: 20px;
    }
    blockquote + h1
    {
        margin-top: 74px;
        // ?
    }
    h1 + blockquote
    {
        margin-top: 21px;
        // ?
    }
    h2 + h2
    {
        margin-top: 19px;
    }
    ol + h2
    {
        margin-top: 48px;
    }
    h2 + ol
    {
        margin-top: 23px;
    }
    ul + h2
    {
        margin-top: 48px;
    }
    h2 + ul
    {
        margin-top: 23px;
    }
    blockquote + h1
    {
        margin-top: 69px;
    }
    h1 + blockquote
    {
        margin-top: 21px;
    }
    ul + ol
    {
        margin-top: 23px;
    }
    ol + ul
    {
        margin-top: 24px;
    }
    blockquote + ul
    {
        margin-top: 30px;
    }
    ul + blockquote
    {
        margin-top: 27px;
    }
    hr
    {
        margin: 0;
    }
    hr + p
    {
        margin-top: 51px;
    }
    p + hr
    {
        margin-top: 51px;
    }
    hr + h1
    {
        margin-top: 59px;
    }
    h1 + hr
    {
        margin-top: 58px;
    }
    h2 + hr
    {
        margin-top: 53px;
    }
    hr + h2
    {
        margin-top: 48px;
    }
    blockquote + h2
    {
        margin-top: 59px;
    }
    h2 + blockquote
    {
        margin-top: 19px;
    }
    hr + ul
    {
        margin-top: 59px;
    }
    ul + hr
    {
        margin-top: 61px;
    }
    hr + ol
    {
        margin-top: 60px;
    }
    ol + hr
    {
        margin-top: 61px;
    }
    blockquote + ol
    {
        margin-top: 25px;
    }
    ol + blockquote
    {
        margin-top: 25px;
    }
    hr + hr
    {
        margin-top: 23px;
    }
    blockquote + hr
    {
        margin-top: 49px;
    }
    hr + blockquote
    {
        margin-top: 51px;
    }
    blockquote + blockquote
    {
        margin-top: 28px;
    }
}
.my-emoji-item
{
    font-size: 20px;
}
.mention
{
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    color: #80BEEA;
}

.img-wrapper
{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    object-fit: cover;
}
/* Basic editor styles */
.emojiCustomPicker
{
    position: absolute;
    z-index: 9999;
}
.editor__toolbar
{
    display: flex;
    .icon
    {
        color: #BABABA;
    }
    button
    {
        margin-right: 1px;
        padding: 4px 7.5px;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        transition: .3s;
        cursor: pointer;

        & > .icon[data-name='emoji'] > svg > circle {
            color: #222;
        }

        @include on-mobile {
            margin-right: 14px;
            scroll-snap-align: start;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    button:hover
    {
        background: rgba(44, 44, 44, 0.7);
        border-radius: 8px;
    }
    button:hover .icon
    {
        color: #fff;
    }
    .link-disabled { cursor: not-allowed; }
    .link-acitve
    {
        background: rgba(44, 44, 44, 0.7);
        border-radius: 8px;
        .icon
        {
            color: #fff;
        }
    }
}
.ProseMirror-focused
{
    outline: none;
}

.ProseMirror {
    span
    {
        img
        {
            height: 19px;
            width: 21px;
            margin-top: -7px;
        }
    }
    figure {
        max-width: 25rem;
        border: 3px solid #0d0d0d;
        border-radius: 0.5rem;
        margin: 1rem 0;
        padding: 0.5rem;
    }

    figcaption {
        margin-top: 0.25rem;
        text-align: center;
        padding: 0.5rem;
        border: 2px dashed #0d0d0d20;
        border-radius: 0.5rem;
    }

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin: 0;
    }
    h1
    {
        // margin-bottom: 36px;
        // margin-top: 44px;
        color: #F4F4F4;
        font-size: 36px;
        font-weight: 400;
        line-height: normal;
    }
    h2
    {
        // margin-bottom: 25px;
        // margin-top: 30px;
        color: #F4F4F4;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    blockquote {
        padding-left: 22px;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 0.5px solid rgba(255, 255, 255, 0.2);
        // margin: 23px 0;
    }
}
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;
    margin: 5px 0;
    align-items: self-start;
    > label {
        display: flex;
      flex: 0 0 auto;
      margin-right: 0.5rem;
      margin-top: 3px;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}
.ProseMirror blockquote
{
    border-left: 1px solid rgba(104, 103, 103, 1);
    margin-left: 0;
    // margin-bottom: 36px;
    // margin-top: 36px;
}
.ProseMirror p.is-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
  height: 0;
  
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: rgba(221, 221, 221, 0.5);
}

.ProseMirror blockquote {
    ol.ordered-list {
        margin-left: 22px;
    }

    ul.horisontal-select {
        padding: 0 30px;
    }
    p {
        padding: 0;
        margin: 0;
    }
}

.ProseMirror ol.ordered-list
{
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
    .my-custom-class
    {
        position: absolute;
        top:-40px;
        width: 100%;
    }
    list-style: none;
    margin-left: 0;
    padding: 0;
    word-break: break-all;
    @include on-mobile {
        margin-left: 19px;
    }

    li {
        align-items: center;
        position: relative;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #DDDDDD;
        counter-increment: custom;
        p
        {
            padding: 0;
            margin: 0;
            margin-left: 26px;
        }
    }

    & li:before {
        height: 19px;
        content: counter(custom) " ";
        position: absolute;
        top: 4px;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #8C8C8C;
        text-align: right;
        width: 26px;
        display: flex;
    }

    & li:first-child {
        counter-reset: custom;
    }
    & li:last-child {
        margin-bottom: 0;
    }
}
iframe {
    border: 8px solid #000;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    display: block;
    outline: 0px solid transparent;
  }

  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  .ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    outline: 6px solid #ece111;
  }

</style>
