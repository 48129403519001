<template>
	<div class="documents-dashboard">
		<div class="container">
			<DocumentsHeader
				v-if="marks"
				:marks="marks"
				@changeMark="changeMarkChecked"
				@uncheckAllMarks="uncheckAllMarks"
			/>
			<Preloader :loading="isLoading" />
			<template v-if="!isLoading">
				<EmptyBackplate
					v-if="showEmpty"
					to="/documents/create"
					message="Еще не добавлено ни одного знания"
				/>
			</template>
		</div>
		<template v-if="!isLoading">
			<DocumentsCanban
				v-for="data in knowledges"
				:key="data.id"
				:data="data"
			/>

			<DocumentsCanban
				v-if="knowledges && knowledges.for_all"
				:data="{ items: knowledges.for_all }"
				:title="'Для всех'"
			/>
		</template>
	</div>
</template>

<script>
// import components
import DocumentsHeader          from '@/components/documents/documentsDashboardHeader';
import DocumentsCanban          from '@/components/documents/documentsDashboardCanban';
import EmptyBackplate           from '@/components/app-bar/EmptyBackplate.vue';
import Preloader                from '@/components/preloader/PreloaderWrap.vue';

// import usable functional
import Notify from '@/services/helpers/notify';

// import modules
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import TagsModule      from '@/store/modules/tags/TagsModule';

export default {
	name: 'DocumentsDashboard',

	components:
	{
		DocumentsHeader,
		DocumentsCanban,
		Preloader,
		EmptyBackplate,
	},

	data()
	{
		return {
			isLoading    : true,
			marks        : null,
			knowledges   : null,
			rolesArray   : null,
			filterTimeout: null,
		};

	},

	computed:
	{
		showEmpty()
		{
			if ( (this.knowledges && this.knowledges.for_all.categories.length === 0) && (this.knowledges && (Object.keys(this.knowledges).length === 1)) )
				return true;
			else
				return false;
		},
	},

	methods:
	{
		changeMarkChecked(markId)
		{
			const idx = this.marks.findIndex(el => el.id === markId);
			this.marks[idx].checked = !this.marks[idx].checked;

			// fetching knowledges with filter
			clearInterval(this.filterTimeout);
			this.filterTimeout = setTimeout(() => {
				let filter = '?';

				this.marks.forEach((el, i) => {
					if (el.checked)
						filter = filter + `&filter[tags][${i}]=` + el.id;
				});

				this.fetchKnowledges(filter.replace('?&', '?'));
			}, 1000);
		},

		uncheckAllMarks()
		{
			this.marks.forEach(el => el.checked = false);
			this.fetchKnowledges();
		},

		async fetchKnowledges(filter = '')
		{
			this.isLoading = true;
			KnowledgeModule.fetchKnowledges(filter)
				.then((res) => {
					this.knowledges = res.data.data;

					this.isLoading = false;
				})
				.catch((e) => {
					Notify.error('Что-то пошло не так, попробуйте позже.');
				})
		},

		async fetchMarks()
		{
			await TagsModule.fetchTags()
				.then(() => {
					this.marks = TagsModule.tags.map((el) => {
						return {
							id: el.id,
							title: el.name,
							color: el.color,
							checked: false,
						};
					});
				});
		},
	},

	mounted()
	{
		this.fetchMarks();
		this.fetchKnowledges();
	},
}
</script>

<style lang="scss" scoped>
	.container
	{
		padding: 30px 22px;
		max-width: 1440px;
		margin: 0 auto;
	}

	.controls
	{
		margin-top: 1em;
	}

	.controls > div
	{
		padding-top: 1em;
	}

	label
	{
		display: block;
		line-height: 1.6em;
	}

	.columns
	{
		display: flex;
		justify-content: stretch;
	}

	.column
	{
		padding: 20px;
		background: #ffffff;
		color: black;
		transition: all .2s ease;
		margin-right: 20px;
		flex: 1;
	}

	.column
	{
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.column .smooth-dnd-container.vertical
	{
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.a
	{
		width: 20px;
		height: 20px;
		background: black;
	}
</style>